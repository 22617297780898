<template>
	<section class="card shadow rythm-v"
					 v-if="caseData">
		<h2>
			Informations sur le patient <strong>{{ caseData.pid }}</strong>
		</h2>

		<div class="form-wrapper">
			<form ref="saveCaseForm"
						id="saveCaseForm"
						method="post"
						@submit.prevent="saveCase">
				<div class="flex-row rythm-v">
					<block-quote>
						Renseignez les informations d'identification du patient et
						sélectionnez les critères d'inclusion et d'exclusion pour déterminer
						si ce patient peut être inclus.
					</block-quote>

					<div class="flex-row">

						<div class="flex-item--30">
							<div class="label">Initiales <span class="hint">(Prénom, Nom)</span></div>
							<div class="flex-row flex-gap"
									 style="margin-top:.5rem">

								<div class="input-group required">
									<input type="text"
												 name="initials_lastname"
												 id="initials_lastname"
												 v-model="caseData.initials_firstname"
												 required
												 style="width:4rem;text-align:center"
												 placeholder="P" />

								</div>

								<div class="input-group required">
									<input type="text"
												 name="initials_lastname"
												 id="initials_lastname"
												 v-model="caseData.initials_lastname"
												 required
												 style="width:4rem;text-align:center"
												 placeholder="N" />
								</div>

							</div>
						</div>


						<div class="flex-item--30">
							<div class="input-group required">
								<label for="sex">Sexe</label>
								<select v-model="caseData.sex"
												name="sex"
												id="sex"
												required>
									<option value="">Choisir une option</option>
									<option value="female">Féminin</option>
									<option value="male">Masculin</option>
								</select>
							</div>
						</div>

						<div class="flex-item--30">
							<div class="input-group required">
								<label for="birthdate">Date de naissance
									<span class="hint">(au format mois/année)</span></label>
								<input type="text"
											 pattern="[0-9]{2}/[0-9]{4}"
											 name="birthdate"
											 id="birthdate"
											 v-model="caseData.birthdate"
											 required
											 placeholder="mm/aaaa" />
							</div>
						</div>

						<div class="flex-item--50 rythm-v">

							<div class="input-group required">
								<label for="center">Centre</label>
								<select name="center"
												id="center"
												v-model="caseData.center_id"
												v-if="centers"
												:disabled="!centers.length"
												@change="updateCenterReferrer()"
												required>
									<option value="">Choisir une option</option>
									<option v-for="(center, index) in centers"
													:value="center.id"
													:key="`opt_${index}`">
										{{ `${center.number} - ${center.name}` }}
									</option>
								</select>
							</div>

							<!-- ARC only, otherwise investigator is current user-->
							<div class="input-group required"
									 v-if="userIsAdmin">
								<label for="investigator_id">Investigateur</label>
								<select name="investigator_id"
												id="investigator_id"
												v-model="caseData.investigator_id"
												required>
									<option value="">Choisir une option</option>
									<option v-for="(investigator, index) in investigators"
													:value="investigator.id"
													:key="`investigator_${index}`">
										{{ `${investigator.firstname} ${investigator.lastname}` }}
									</option>
								</select>
							</div>
						</div>

						<div class="flex-item--50">
							<div class="input-group">
								<label for="referrer">Référent du centre</label>
								<strong v-if="centerReferrer">
									{{ centerReferrer }}
								</strong>
								<em v-else>Choisir un centre</em>
							</div>
						</div>
					</div>

					<section class="flex-row flex-item--50 rythm-v">
						<h3 class="flex-item--100">Critères d'inclusion</h3>

						<div class="input-group">
							<label v-for="(caption, index) in criteria.inclusion"
										 :key="`inclusion_${index}`">
								<input type="checkbox"
											 :name="`inclusion_${index}`"
											 :value="caption"
											 v-model="caseData.inclusion_data.inclusion" />
								<span class="caption">{{ caption }}</span>
							</label>
						</div>

						<div class="rythm-v">
							<div class="input-group required">
								<label for="patient_consent_date">Date de l’accord de non opposition :</label>
								<input type="date"
											 name="patient_consent_date"
											 id="patient_consent_date"
											 form="saveForm"
											 v-model="caseData.inclusion_data.patient_consent_date"
											 required />
							</div>
						</div>
					</section>

					<section class="flex-row flex-item--50 rythm-v">
						<h3 class="flex-item--100">Critères d'exclusion</h3>

						<div class="input-group">
							<label v-for="(caption, index) in criteria.exclusion"
										 :key="`exclusion_${index}`">
								<input type="checkbox"
											 :name="`exclusion_${index}`"
											 :value="caption"
											 v-model="caseData.inclusion_data.exclusion" />
								<span class="caption">{{ caption }}</span>
							</label>
						</div>
					</section>

					<div class="flex-item--100 text-center rythm-v"
							 id="patient-inclusion-status"
							 role="alert"
							 aria-live="polite">
						<p :class="canIncludePatient ? 'alert-success' : 'alert-error'">
							<transition name="fade">
								<strong key="cannotInclude"
												v-show="!canIncludePatient">Le patient ne peut pas être inclus dans l'étude.</strong>
							</transition>
							<transition name="fade">
								<strong key="cnnInclude"
												v-show="canIncludePatient">Le patient peut-être inclus dans l'étude.</strong>
							</transition>
						</p>
					</div>

					<!-- Le patient participe à l'étude ancillaire Saint Antoine et Cochin -->
					<div v-if="canIncludePatient && isAncillaryStudyCenter"
							 class="flex-item--100 flex-row flex-center"
							 style="padding: 2rem">
						<div class="input-group">
							<label for="ancillary_study_participation">
								<input type="checkbox"
											 id="ancillary_study_participation"
											 name="ancillary_study_participation"
											 v-model="caseData.ancillary_study_participation" />
								<span class="caption">Le patient participe à l'étude ancillaire de Saint-Antoine et Cochin</span>
							</label>
						</div>

					</div>


					<div class="input-group--controls flex-item--100 flex-row flex-center-h">
						<button type="submit"
										class="button--primary"
										:disabled="!canIncludePatient">
							<span class="caption">Continuer</span>
							<svg role="img"
									 class="icon icon-right"
									 viewBox="0 0 15 15"
									 fill="none"
									 xmlns="http://www.w3.org/2000/svg"
									 width="15"
									 height="15">
                <path
                  d="M5 14l7-6.5L5 1"
                  stroke="currentColor"
                  stroke-linecap="square"
                ></path>
              </svg>
						</button>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/scss/common-views.scss";
</style>

<script>
import BlockQuote from "@/components/BlockQuote.vue";

export default {
	name: "DashboardCasePatient",
	props: ["caseID"],
	components: { BlockQuote },

	data: function () {
		return {
			isMounted: false,

			// Available centers
			centers: [],

			// Selected referrer
			centerReferrer: "",
			investigators: [],

			// Case data
			caseData: null,
			caseDataDefaults: {
				investigator_id: 0, // replace with current user id
				center_id: "",
				initials_lastname: "",
				initials_firstname: "",
				pid: "",
				inclusion_data: {
					inclusion: [],
					exclusion: [],
					patient_consent_date: "",
				},
				ancillary_study_participation: false // Saint Antoine et Cochin
			},

			// Other form data
			criteria: {
				inclusion: [
					"Âge ≥ 18 ans à l’inclusion",
					"Diagnostic RIC posé par le rhumatologue prenant en charge le patient",
					"Initiation d’un traitement par système Adacolumn®",
					"Échec et/ou intolérance aux traitements de fond conventionnels (méthotrexate, léflunomide, sulfasalazine, hydroxychloroquine), biothérapies (anti-TNF, anti-IL6R, anti-IL17, anti IL12/23, abatacept, rituximab) et synthétiques ciblés (inhibiteurs de JAK, apremilast)",
					"Informations sur le rapport bénéfice / risque donné au patient",
					"Patient affilié à un régime de sécurité sociale ou CMU",
					"Patient ayant donné un accord de non opposition",
				],
				exclusion: [
					"Personnes dans l’incapacité de comprendre, de lire ou de donner un accord de non opposition",
					"Opposition orale au moment de l’inclusion ou à posteriori",
					"Grossesse et/ou allaitement",
					"Refus du traitement par système Adacolumn®",
					"Refus de participation"
				],
			},
		};
	},

	computed: {

		// Current user data
		user: function () {
			return this.$store.state.userData;
		},

		// Is the user a admin? (ARC)
		userIsAdmin: function () {
			return this.user && this.userCan("manage_users", this.user);
		},

		// Check if the selected center participates in the ancillary study
		isAncillaryStudyCenter() {
			if (!this.caseData?.center_id || !this.centers.length) return false;

			const participatingCenters = this.centers
				.filter(center => center.ancillary_study)
				.map(center => parseInt(center.id));

			if (participatingCenters.length === 0) return false;

			return participatingCenters.includes(parseInt(this.caseData?.center_id));;
		},

		// No caseData item is empty and canIncludePatient is true
		canIncludePatient() {
			return (
				// All inclusion criteria are checked
				this.caseData.inclusion_data.inclusion.length ===
				this.criteria.inclusion.length &&
				// No exclusion criteria is checked
				this.caseData.inclusion_data.exclusion.length === 0
			);
		},
	},

	watch: {

		"caseData.initials_lastname": function (val) {
			this.caseData.initials_lastname = val.toUpperCase();
		},
		"caseData.initials_firstname": function (val) {
			this.caseData.initials_firstname = val.toUpperCase();
		},

		// When the route changes from "/cases/{caseID}/patient" to "/cases/new"
		// $route.params.caseID changed and is now undefined
		"$route.params.caseID"(val) {
			if (typeof val === "undefined") {
				// Clase the current case (switch from case edit to case insert mode)
				this.resetCaseData();
			}
		},
	},

	methods: {

		// Get all investigators
		getInvestigators: async function () {
			let users = this.$store
				.dispatch("GET_USERS")
				.then((response) => {
					// remove admins
					return response.filter(user => user.role_id !== 4);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});

			return users;
		},

		// Save the case
		saveCase() {
			this.$store
				.dispatch("SAVE_CASE", this.caseData)
				.then((caseData) => {
					this.$toasted.clear();

					let message = !this.caseID
						? "Nouveau patient enregistré&nbsp;!"
						: "Informations du patient enregistrées";

					let toast = this.$toasted.global.appSuccess({
						message: message,
					});
					setTimeout(function () {
						toast.goAway();
					}, 5000);

					this.scrollToTop();
					this.$router.push(`/cases/${caseData.id}/mici`);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
		},

		// Display the referrer name of the selected center
		updateCenterReferrer: async function () {

			if (!this.caseData || !this.caseData.center_id) {
				this.centerReferrer = "";
				// this.caseData.ancillary_study_participation = false;
				return;
			}

			// Get the center object of the selected center ID
			// and display the center user name.
			let currentCenter = this.centers.find(
				(el) => parseInt(el.id) === parseInt(this.caseData.center_id)
			);

			if (!currentCenter) {
				this.centerReferrer = "";
				this.caseData.ancillary_study_participation = false;
			}
			else {
				this.centerReferrer = currentCenter.center_user_name;
			}

			if (!this.isAncillaryStudyCenter) {
				this.caseData.ancillary_study_participation = false;
			}
		},

		// Get all centers
		getCenters: function () {
			let centers = this.$store
				.dispatch("GET_CENTERS")
				.then((response) => {
					return response;
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
			return centers;
		},

		// Load case data from database
		loadCaseData: function () {
			this.$store
				.dispatch("GET_CASE_DATA", this.caseID)
				.then(async (payload) => {
					this.caseData = await this.$store.state.caseData;
					if (!this.caseData?.investigator_id) this.caseData.investigator_id = parseInt(this.user.id);
					this.updateCenterReferrer();
				})
				.catch((error) => {
					this.resetCaseData();
				});
		},

		resetCaseData: function () {
			this.centerReferrer = "";
			this.caseData = { ...this.caseDataDefaults };
			this.caseData.investigator_id = parseInt(this.user.id);
			this.$store.dispatch("CLOSE_CASE");
		},

		initCaseData: async function () {
			const jsonSchemas = await this.$store.state.jsonSchemas;
			this.caseData = { ...this.caseDataDefaults };
			this.caseData.investigator_id = parseInt(this.user.id);
			this.caseData.desease_data = { ...jsonSchemas.desease_data, ...this.caseData?.desease_data };
			this.caseData.treatments = { ...jsonSchemas.treatments, ...this.caseData?.treatments };
			this.caseData.sessions = { ...jsonSchemas.sessions, ...this.caseData?.sessions };
		}
	},

	async mounted() {
		this.isMounted = true;

		// Init data
		this.initCaseData();

		// Load centers
		this.centers = await this.getCenters();

		// Load users
		this.investigators = this.userIsAdmin ? await this.getInvestigators() : null;

		// Edit? load patient
		if (this.caseID) this.loadCaseData();
	},
};
</script>
